.fc {
  a {
    color: var(--nxmoov-colors-gray-700);
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .fc-timeGridDay-view {
    .fc-day {
      &.fc-col-header-cell {
        text-align: left;
      }
    }
  }

  .fc-day {
    background-color: var(--nxmoov-colors-white);

    .fc-daygrid-day-number {
      font-weight: var(--nxmoov-fontWeights-medium);
    }

    &.fc-col-header-cell {
      text-align: right;
      padding: 7.5px 0;

      .fc-col-header-cell-cushion {
        color: var(--nxmoov-colors-gray-600) !important;
        font-weight: normal;
        margin: 4px;
        padding: 0 12px;
      }

      &.fc-day-today {
        .fc-col-header-cell-cushion {
          background-color: var(--nxmoov-colors-primary-500) !important;
          color: white !important;
          border-radius: 4px;
        }
      }
    }

    &.fc-daygrid-day.fc-day-today {
      background-color: var(--nxmoov-colors-white);

      .fc-daygrid-day-number {
        background-color: var(--nxmoov-colors-primary-500);
        color: var(--nxmoov-colors-white);
      }
    }

    &.fc-day-sat,
    &.fc-day-sun {
      background-color: var(--nxmoov-colors-gray-100) !important;

      &.fc-col-header-cell {
        background-color: var(--nxmoov-colors-white);
      }
    }
  }

  .fc-daygrid-day-number {
    align-items: center;
    border-radius: 100%;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 5px !important;
    width: 32px;
  }

  .fc-toolbar-chunk {
    display: flex !important;

    .fc-toolbar-title {
      font-weight: var(--nxmoov-fontWeights-medium);
      display: inline-block;
      font-size: 14px !important;
      line-height: 32px !important;
    }

    .fc-button.fc-prev-button,
    .fc-button.fc-next-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      height: 32px;
      color: #a5a9b9 !important;
      background: #ffffff !important;
      border: 1px solid #d0d2d6 !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08) !important;
      width: 32px;

      .fc-icon {
        font-size: 16px !important;
      }
    }
  }

  .fc-event {
    border-radius: 4px;
    overflow: hidden;

    &.fc-h-event,
    &.fc-v-event {
      background: white;
      border: none;
      overflow: hidden;

      &:hover {
        background: white;
      }
    }

    &.fc-daygrid-event {
      &.fc-daygrid-dot-event {
        padding: 0;
      }
    }
  }

  .fc-button {
    &.fc-button-primary {
      &.fc-new-button,
      &.fc-listWeek-button,
      &.fc-workWeek-button,
      &.fc-timeGridDay-button,
      &.fc-timeGridWeek-button,
      &.fc-dayGridMonth-button {
        background: white;
        border: none;
        border: 1px solid #d0d2d6;
        border-radius: 4px;
        box-shadow: 0px 1px 2px rgb(0 0 0 / 8%) !important;
        color: var(--nxmoov-colors-gray-600);
        font-weight: var(--nxmoov-fontWeights-medium);
        height: 32px;
        font-size: 12px;
        padding: 0 16px;
        text-transform: capitalize;
        transition: 0.3s linear;

        &:hover {
          background: var(--nxmoov-colors-gray-500);
          border-color: var(--nxmoov-colors-gray-500);
          color: white;
        }

        &.fc-button-primary:not(:disabled) {
          &.fc-button-active {
            background: var(--nxmoov-colors-gray-600);
            border-color: var(--nxmoov-colors-gray-600);
            color: white;
          }
        }
      }

      &.fc-new-button {
        background-color: var(--nxmoov-colors-primary-500);
        border-color: var(--nxmoov-colors-primary-500);
        color: white;

        &:hover {
          background-color: var(--nxmoov-colors-primary-600);
          border-color: var(--nxmoov-colors-primary-600);
        }
      }
    }
  }

  .fc-col-header,
  .fc-timegrid-body,
  .fc-timegrid-body table {
    width: 100% !important;
  }
}

.fc-theme-standard .fc-scrollgrid {
  border-color: var(--nxmoov-colors-gray-200) !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: var(--nxmoov-colors-gray-200) !important;
}
