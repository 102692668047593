.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.public-DraftEditor-content[contenteditable='true'] {
  -webkit-user-modify: read-write-plaintext-only;
}
.DraftEditor-root {
  position: relative;
}
.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 0 !important;
}
.public-DraftEditor-block {
  position: relative;
}
.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}
.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left;
}
.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}
.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}
.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right;
}
.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 0;
}
.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}
.DraftEditorPlaceholder-hidden {
  display: none;
}
.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}
.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}
.public-DraftStyleDefault-listLTR {
  direction: ltr;
}
.public-DraftStyleDefault-listRTL {
  direction: rtl;
}
.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}
.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}
.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}
.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) '. ';
  counter-increment: ol0;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) '. ';
  counter-increment: ol1;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) '. ';
  counter-increment: ol2;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) '. ';
  counter-increment: ol3;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) '. ';
  counter-increment: ol4;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.tinyMce-wrapper {
  background: white !important;
  display: grid !important;
  grid-template-rows: max-content 1fr !important;
  grid-template-areas: 'toolbar' 'editor';
  height: 100% !important;
  position: relative;
  z-index: 1;

  .public-DraftStyleDefault-block {
    margin-top: 0 !important;
  }

  &.tinyMce-wrapper__gray-bg {
    background: #F8FAFB !important;
    .rdw-editor-toolbar {
      background: #F8FAFB !important;
    }
  }

  &.tinyMce-wrapper-email-editor {
    border: 1px solid #dae1e3 !important;
    border-radius: 4px !important;
    padding: 16px !important;
    box-sizing: border-box;
    grid-template-rows: 1fr max-content !important;
    grid-template-areas: 'editor' 'toolbar';
  }
}

.tinyMce-toolbar {
  align-items: center !important;
  z-index: 2;
  border: 1px solid #dae1e3 !important;
  border-radius: 4px 4px 0px 0px !important;
  display: flex !important;
  height: 40px !important;
  padding: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  margin: 0 !important;
  position: sticky;
  grid-area: toolbar;
  top: 0;
  row-gap: 5px !important;

  &.tinyMce-toolbar-email-editor {
    align-items: stretch !important;
    border: none !important;
    padding: 0 !important;
    height: 57px !important;
    margin-top: 16px !important;
    padding-top: 16px !important;
    border-top: 1px solid #dae1e3 !important;
    border-radius: 0 !important;

    .rdw-dropdown-wrapper {
      .rdw-dropdown-optionwrapper {
        top: 39px;
      }
    }
  }

  .rdw-inline-wrapper,
  .rdw-image-wrapper,
  .rdw-list-wrapper {
    display: flex !important;
    margin: 0 !important;
    padding: 0 !important;

    .rdw-option-wrapper {
      border: none !important;
      box-shadow: none !important;
      opacity: 0.35 !important;
      margin: 0 16px 0 0 !important;
      padding: 0 !important;

      &.rdw-option-active {
        opacity: 1 !important;
      }
    }

    &:last-child {
      .rdw-option-wrapper {
        &:last-of-type {
          margin: 0 !important;
        }
      }
    }
  }

  .rdw-fontfamily-wrapper,
  .rdw-fontsize-wrapper {
    display: flex !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;

    .rdw-dropdown-wrapper {
      border: 1px solid #dae1e3 !important;
      box-shadow: none !important;
      border-radius: 4px;
      height: 100%;
      margin: 0 16px 0 0 !important;
      padding: 0 8px !important;
      position: relative;
      width: 100%;

      .rdw-dropdown-selectedtext {
        display: flex;
        color: #212933;
        text-decoration: none;
        justify-content: space-between;

        .rdw-dropdown-carettoopen {
          border: none;
          background: url('/images/caretToOpen.svg');
          height: 16px;
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          width: 16px;
        }

        .rdw-dropdown-carettoclose {
          border: none;
          background: url('/images/caretToClose.svg');
          height: 16px;
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          width: 16px;
        }
      }

      .rdw-dropdown-optionwrapper {
        background: #ffffff;
        border: 1px solid #dae1e3 !important;
        box-shadow: none;
        left: 0;
        max-height: 125px;
        padding: 8px !important;
        position: absolute;
        width: 100%;
        z-index: 100;

        li {
          color: #212933;
        }
      }
    }
  }

  .rdw-fontfamily-wrapper {
    flex: 1;
  }

  .rdw-fontsize-wrapper {
    width: 80px;
  }

  .rdw-image-wrapper[aria-expanded='true'] {
    .rdw-option-wrapper {
      opacity: 1 !important;
    }
  }
}

.tinyMce-editor {
  background: transparent !important;
  border-radius: 0px 0px 4px 4px !important;
  border: 1px solid #dae1e3 !important;
  border-top-width: 0 !important;
  color: #17191a !important;
  overflow: auto !important;
  padding: 16px !important;
  grid-area: editor;

  &.tinyMce-editor-email-editor {
    border: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }
}

.Select-menu-outer {
  position: relative;
}
